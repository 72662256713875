import React from "react";
import Title from "../../../components/Title/Title";
import './RegistrationPage.scss';

const Registration2025page = () => {
    return (
        <div>
            <Title>Venue and Registration</Title>
            <h5>Venue</h5>
            <p>CLeaR 2025 will be held at the <a href="https://www.stcc.ch"><b>SwissTech Convention Center</b></a>, <b>Laussane</b>, Switzerland, on May 7-9, 2025. </p>
            <h5>Registration</h5>
            <p>TBA</p>
            <h5>Hotel Accommodations</h5>
            <p>Below please find preferential rates for accommodations.</p>
            <ul>
                <li>
                    <a href="https://www.swisstech-hotel.com"><b>SwissTech Hotel</b></a> (on the conference site)
                    <p>To reserve a room: send an email to <a href="mailto:reception@sthotel.ch">reception@sthotel.ch</a>, with the following information:</p>
                    <ul>
                        <li>Last name, first name</li>
                        <li>Exact arrival and departure dates</li>
                        <li>Number of persons</li>
                        <li>Breakfast yes or no (surcharge of CHF 16.--)</li>
                    </ul>
                    <p>Reservation code: CLEAR25</p>
                    <p>Room price: CHF 130.--</p>
                    <p>Breakfast: CHF 16.--/person</p>
                    <p>Tourist taxes: CHF 5.50/person</p>
                </li>
                <li>
                    <a href="https://www.starling-hotel-lausanne.com"><b>Starling Hotel Lausanne</b></a> (about 10-min walk from the conference)
                    <p>To reserve a room before April 6, 2025: <a href="http://bookings.ihotelier.com/bookings.jsp?groupID=4475883&hotelID=73897">http://bookings.ihotelier.com/bookings.jsp?groupID=4475883&hotelID=73897</a></p>
                    <p>To reserve a room from April 7, 2025 on: send an email to <a href="mailto:s.mesnil@shlausanne.ch">s.mesnil@shlausanne.ch</a> with code CLEAR25, groupID 4475883</p>
                    <p>Room price per person: CHF 170.--</p>
                    <p>Supplement for double occupancy: CHF 35.--</p>
                    <p>Breakfast buffet and parking included</p>
                    <p>Tourist taxes: CHF 6.--/person</p>
                </li>
                <li>
                    <a href="https://www.hotelcontinental.ch"><b>Hotel Continental Lausanne</b></a> (in front of the Lausanne train station)
                    <p>To reserve a room: send an email to <a href="mailto:booking@hotelcontinental.ch">booking@hotelcontinental.ch</a>, with the following information:</p>
                    <ul>
                        <li>Name, first name</li>
                        <li>Birth date</li>
                        <li>Kind and number of personal document</li>
                        <li>Credit card number (for guarantee)</li>
                        <li>Exact arrival and departure dates</li>
                        <li>Number of persons</li>
                    </ul>
                    <p>Reservation code: CLEAR25</p>
                    <p>Room price: CHF 195.--</p>
                    <p>Breakfast: included</p>
                    <p>Bus and metro tickets: included</p>
                    <p>Tourist taxes: CHF 6.--/person</p>
                    <p>Baggage taxes: CHF 4.--/baggage (if needed)</p>
                </li>
                <li>
                    <a href="https://www.marriott.com/en-us/hotels/gvaox-moxy-lausanne-city/overview/?scid=f2ae0541-1279-4f24-b197-a979c79310b0"><b>Hotel Moxy Lausanne City</b></a> (town center, close to lively and fashionable district)
                    <p>To reserve a room: <a href="https://www.marriott.com/event-reservations/reservation-link.mi?id=1733921465421&key=GRP&guestreslink2=true&app=resvlink"><b>use this link</b></a> to book with group rate for EPFL until March 26, 2025 (after this date, the price will be the regular market price). At your arrival, you will have to deposit a credit card number for guarantee.</p>
                    <p>Room price single room: CHF 190.--</p>
                    <p>Room price double room: CHF 205.--</p>
                    <p>Breakfast: included</p>
                    <p>Tourist taxes: CHF 5.50/person</p>
                </li>
            </ul>
        </div>
    )
}

export default Registration2025page

